<template>
    <div class="row">
        <div class="col-12">
            <table class="table table-striped table-hover tx-white">
                <thead class="thead-colored thead-primary">
                    <tr>
                        <th>Email</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="u in users">
                        <td>{{u.email}}</td>
                        <td><button v-if="$can('users-edit-password')" class="btn btn-sm btn-warning" style="color: black !important;" @click="resetPassword(u)">Password Reset</button></td>
                    </tr>
                    <tr v-if="users.length < 1">
                        <td colspan="2" class="text-center">No Users</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import authHeader from '@/services/auth-header';

export default {
    name: "SiteUsers",
    props: {
      site: Object,
      users: Array
    },
    methods: {
      resetPassword(user){
        axios.patch(`https://server.varsanpr.com/api/users/${user.id}/password/reset`, {
            headers: authHeader()
        })
                    .then(response => {
                        this.$success("Sent password reset email");
                    })
                    .catch(error => {
                        this.$error("Failed to send password reset email", error);
                    });
      }
    }
}
</script>

<style scoped>

</style>
