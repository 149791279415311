<template>
    <div>
        <div class="row row-sm">
            <div class="col-lg-4 col-md-12 col-sm-12"></div>
            <div class="col-lg-8 col-md-12 col-sm-12">
                <button v-if="$can('sites-edit')" class="btn btn-sm btn-primary ft-right" @click="openAddModal">Upload File</button>
            </div>
        </div>
        <div class="row">

            <div class="col-lg-4 col-md-12 col-sm-12">
                <p>Preview: <span v-if="selectedAsset !== null">{{selectedAsset.filename}}</span></p>
                <p class="tx-white tx-bold" v-if="selectedAsset == null">Please click on a file to view it!</p>
                <secure-image v-if="selectedAsset !== null && isImage" :endpoint="'/v2/site/' + site.id + '/documents/'" :filename="selectedAsset.filename" :site_id="'' + site.id" width="100%;" height="auto"></secure-image>
                <iframe v-if="selectedAsset !== null && isPdf" :src="pdf_url" width="100%" height="750px" min-height="500px;"></iframe>
                <p v-if="selectedAsset != null && !isPdf && !isImage">Unfortunately this filetype is not supported in VARS. Please download it to view it.</p>
            </div>
            <div class="col-lg-8 col-md-12 col-sm-12">
                <p v-if="selected > 0">{{selected}}/{{site.assets.length}} files selected </p>
                <submit-button v-if="selected > 0 && selected < 11" :background="'btn btn-sm btn-primary float-right'" :clicked="downloading" :text="'Download ' + selected + ' Items'" @click="downloadEvidence"></submit-button>
                <p class="float-right text-warning" v-if="selected > 10">You can only download up to 10 items at once!</p>
                <table class="table mg-b-0">
                    <thead>
                    <tr>
                        <th class="wd-5p">
                            <label class="ckbox mg-b-0">
                                <input v-model="allChecked" type="checkbox" @change="toggleAllCheck">
                                <span></span>
                            </label>
                        </th>
                        <th class="tx-10-force tx-mont tx-medium">Name</th>
                        <th class="tx-10-force tx-mont tx-medium hidden-xs-down">Evidence Type</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr v-for="asset in site.assets" :class="{'tx-bold tx-white': currentId === asset.id}">
                            <td class="valign-middle">
                                <label class="ckbox mg-b-0">
                                    <input v-model="asset.checked" type="checkbox">
                                    <span></span>
                                </label>
                            </td>
                            <td @click="selectAsset(asset)" style="cursor: pointer;">{{asset.filename}}</td>
                            <td v-if="$can('sites-edit')" style="cursor: pointer;">
                                <select v-model="asset.type" class="form-control select2" v-on:change="evidenceTypeChanged(asset)" v-select2>
                                    <option v-for="type in availableTypes" v-bind:value="type.id">{{type.name}}</option>
                                </select>
                            </td>
                            <td v-else>{{getType(asset.type)}}</td>
                            <td @click="selectAsset(asset)" style="cursor: pointer;"></td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
        <div :id="'addModal' + site.id" class="modal fade" style="display: none; padding-right: 17px;">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content bg-dark tx-white tx-size-sm">
                    <div class="modal-header bg-dark pd-x-20">
                        <h6 class="tx-14 mg-b-0 tx-uppercase tx-white tx-bold">Add Asset to Site</h6>
                        <button type="button" class="close tx-white" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body bg-dark tx-white pd-20">

                        <p class="tx-danger tx-bold tx-uppercase">Please make sure you verify the file before you upload it. If the file is suspicious, please ask the support team to verify it.</p>
                        <div class="row no-gutters">
                            <div class="col-12">
                                <div class="form-group custom-file">
                                    <input type="file" accept="image/*,video/*,audio/*,application/pdf,application/msword,application/zip" ref="file" id="evidence_file" @change="selectFile" class="custom-file-input">
                                    <label class="custom-file-label" style="background-color: transparent; color: white;">{{evidence_filename}}</label>
                                </div>
                            </div>
                            <div class="col-12 mg-t-10">
                                <div class="form-group mg-md-l--1">
                                    <label class="form-control-label mg-b-0-force">Asset Type: <span class="tx-danger">*</span></label>
                                    <select id="select2-a" class="form-control" v-model="addEvidence.type" data-placeholder="Choose type" placeholder="Choose Type">
                                        <option value="petroleum_cert" selected="">Petroleum Certificate</option>
                                        <option value="site_schedule">Site Schedule</option>
                                        <option value="dvla_auth">DVLA Authorisation</option>
                                        <option value="parking_contract">Parking Contract</option>
                                        <option value="signage_map">Parking Signage Map</option>
                                        <option v-if="$can('sites-view-contract')" value="contract">Contract</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-12 mg-t-10">
                                <submit-button :background="'btn btn-info'" :text="'Add Asset'" :clicked="addEvidence.uploading" @click="uploadEvidence" />
                                <button class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                            </div><!-- form-group -->
                        </div><!-- row -->

                    </div><!-- modal-body -->
                    <div class="modal-footer bg-dark tx-white">
                        <button id="closeOpenModal" type="button" class="btn btn-secondary tx-size-xs" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div><!-- modal-dialog -->
        </div>

    </div>
</template>

<script>
import SecureImage from "@/components/SecureImage";
import SubmitButton from "@/components/SubmitButton";
import Swal from "sweetalert2";
import saveAs from 'file-saver';
import axios from 'axios';
import authHeader from "@/services/auth-header";

export default {
    name: "SiteAssets",
    components: {
        SecureImage,
        SubmitButton
    },
    props: {
        site: Object
    },
    data(){
        return {
            imageRotation: 0,
            allChecked: false,
            selectedAsset: null,
            popups: {
                till: {
                    showing: false,
                    imageUrl: "",
                    imageLoading: false,
                    asset_id: null
                }
            },
            evidence_file: null,
            addEvidence: {
                type: "petroleum_cert",
                uploading: false
            },
            pdf_url: null,
            currentPage: 1,
            pageCount: 0,
            availableTypes: [
                {
                    id: "petroleum_cert",
                    name: "Petroleum Certificate"
                },
                {
                    id: "site_schedule",
                    name: "Site Schedule"
                },
                {
                    id: "dvla_auth",
                    name: "DVLA Authorisation"
                },
                {
                    id: "parking_contract",
                    name: "Parking Contract"
                },
                {
                    id: "signage_map",
                    name: "Parking Signage Map"
                },
                {
                    id: "contract",
                    name: "Contract"
                },
                {
                    id: "SOFTDELETED",
                    name: "DELETE"
                }
            ],
            downloading: false
        }
    },
    mounted() {
        document.addEventListener('keydown', (e) => {
            if(e.code === 'Escape'){
                this.closeImageTill();
                $('#closeOpenModal').click();
            }
        });
        $('.select2').select2();
    },
    beforeRouteLeave(to, from, next){
        if(this.pdf_url){
            window.URL.revokeObjectURL(this.pdf_url);
        }
        document.removeEventListener('keydown', (e) => {
            if(e.code === 'Escape'){
                this.closeImageTill();
                $('#closeOpenModal').click();
            }
        });
        next();
    },
    methods: {
        getType(id){
            return (this.availableTypes.filter((t) => {
                return t.id === id;
            })[0] || {name: ""}).name
        },
        increaseRotation(){
            this.imageRotation += 90;
        },
        decreaseRotation(){
            this.imageRotation -= 90;
        },
        evidenceTypeChanged(asset){
            if(asset.type === 'SOFTDELETED'){
                Swal.fire({
                    title: 'Are you sure?',
                    text: "This will delete the asset and is not reversible?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        axios.patch(`https://api.varsanpr.com/api/sites/${this.site.id}/documents/${asset.id}`, {
                            type: asset.type
                        }, {
                            headers: authHeader()
                        })
                            .then(response => {
                                if(response.data.success){
                                    this.$success("Successfully updated asset type!");
                                    asset.trueType = asset.type;
                                }
                            })
                            .catch(error => {
                                this.$error("Failed to update asset type.", error);
                            });
                    }else{
                        asset.type = asset.trueType;
                        $('.select2').select2();
                    }
                })
            }else{
                axios.patch(`https://api.varsanpr.com/api/sites/${this.site.id}/documents/${asset.id}`, {
                    type: asset.type
                }, {
                    headers: authHeader()
                })
                    .then(response => {
                        if(response.data.success){
                            this.$success("Successfully updated asset type!");
                            asset.trueType = asset.type;
                        }
                    })
                    .catch(error => {
                        this.$error("Failed to update asset type.", error);
                    });
            }
        },
        nextPage(){
            if(this.currentPage < this.pageCount){
                this.currentPage++;
            }
        },
        previousPage(){
            if(this.currentPage > 1){
                this.currentPage--;
            }
        },
        downloadPdf(){
            if(!this.isPdf){
                this.pdf_url = "";
                return;
            }
            if(this.pdf_url){
                window.URL.revokeObjectURL(this.pdf_url);
            }
            let url = `https://api.varsanpr.com/api/sites/${this.site.id}/documents/${this.selectedAsset.filename}`;
            axios.get(`https://api.varsanpr.com/api/sites/${this.site.id}/documents/${this.selectedAsset.filename}`, {
                responseType: 'arraybuffer',
                headers: authHeader()
            })
                .then(response => {
                    let blob = new Blob([response.data], {type: 'application/pdf'});
                    this.pdf_url = window.URL.createObjectURL(blob);
                })
                .catch(error => {
                    console.log(error);
                    this.pdf_url = "";
                })
        },
        selectFile(event){
            this.evidence_file = event.target.files[0];
        },
        uploadEvidence(){
            if(this.addEvidence.uploading) return;
            this.addEvidence.uploading = true;
            let formData = new FormData();
            formData.append("file", this.evidence_file);
            formData.append("type", this.addEvidence.type);
            axios.put(`https://api.varsanpr.com/api/sites/${this.site.id}/documents`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    ...authHeader()
                }
            })
                .then(response => {
                    this.$success("Uploaded asset file!");
                    this.$emit('newasset', response.data.asset);
                    this.$emit('reload');
                    $('#closeOpenModal').click();
                })
                .catch(error => {
                    this.$error("Failed to upload asset file.", error);
                })
                .finally(() => {
                    this.addEvidence.uploading = false;
                })
        },
        openAddModal(){
            $('#addModal' + this.site.id).modal('toggle');
            $('.select2').select2();
        },
        toggleAllCheck(){
            this.site.assets.forEach((asset) => {
                asset.checked = this.allChecked;
            });
        },
        selectAsset(asset){
            this.selectedAsset = asset;
            this.downloadPdf();
        },
        async downloadEvidence(){

            if(this.downloading) return;

            this.downloading = true;

            let selectedAssets = this.site.assets.filter((a) => {
                return a.checked === true;
            });

            for await (let asset of selectedAssets){
                let url = `https://api.varsanpr.com/api/sites/${this.site.id}/documents/${asset.filename}`;
                await axios.get(url, {
                    responseType: 'arraybuffer',
                    headers: authHeader()
                })
                    .then(response => {
                        saveAs(new Blob([response.data]), asset.filename);
                    })
                    .catch(error => {
                        this.downloading = false;
                        this.$error("Failed to download evidence.", error);
                    })
            }

            this.downloading = false;

        }

    },
    computed: {
        evidence_filename: function(){
            if(this.evidence_file == null){
                return "Select File";
            }
            if(this.evidence_file == null){
                return "File Selected";
            }
            return this.evidence_file.name;
        },
        selected: function(){
            console.log(this.site.assets.filter((a) => {return a.checked === true;}));
            return this.site.assets.filter((a) => {
                return a.checked === true;
            }).length;
        },
        currentId: function(){
            if(this.selectedAsset){
                return this.selectedAsset.id;
            }
            return -1;
        },
        currentFileType: function(){
            if(this.selectedAsset){
                return this.selectedAsset.filename.slice((this.selectedAsset.filename.lastIndexOf(".") - 1 >>> 0) + 2);
            }
            return "";
        },
        isImage: function(){
            switch(this.currentFileType){
                case 'png':
                case 'jpg':
                case 'gif':
                case 'jpeg':
                case 'svg':
                case 'webp':
                case 'apng':
                    return true;
                default:
                    return false;
            }
        },
        isPdf: function(){
            return this.currentFileType === 'pdf';
        },
    }
}
</script>

<style scoped>

</style>