<template>
    <div>
        <p>The system has detected an issue with this No Means of Payment claim. Please review the details provided by the site before continuing.</p>

        <p><strong>Current £/L:</strong> {{ poundsPerLitre }}</p>

        <submit-button :disabled="releaseLocked" :text="'Release No Means of Payment'" :background="'btn btn-success pd-x-30 pd-y-15 tx-uppercase tx-bold tx-spacing-6 tx-12'" :clicked="posting" @click="release"></submit-button>
    </div>
</template>

<script>
import SubmitButton from "@/components/SubmitButton";
import axios from 'axios';
import authHeader from '@/services/auth-header';

export default {
    components: {
        SubmitButton
    },
    name: 'NmopReview',
    props: {
        client_id: Number,
        claim: Object,
    },
    data(){
        return {
            posting: false
        }
    },
    mounted(){

    },
    methods: {
        release(){
            if(this.posting) return;
            this.posting = true;
            axios.patch(`https://server.varsanpr.com/api/claims/${this.claim.id}/letters/nmop`, {
                client_id: this.client_id,
                send: true
            }, {
                headers: authHeader()
            })
            .then(response => {
                this.$success("Released no means of payment!");
                this.$emit('changeMade');
                this.$emit('update');
            })
            .catch(error => {
                this.$error("Failed to release no means of payment!", error);
            })
            .finally(() => {
                this.posting = false;
            });
        }
    },
    computed: {
        poundsPerLitre: function(){
            let value = parseFloat(this.claim.value);
            let litres = parseFloat(this.claim.litres);
            return (value / litres).toFixed(2);
        },
        releaseLocked: function(){
            if(parseFloat(this.poundsPerLitre) < 1.00 || parseFloat(this.poundsPerLitre) > 2.50){
                return true;
            }
            return false;
        }
    }
}
</script>