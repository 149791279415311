<template>
    <div>
        <div class="row">
            <div class="col-12">
                <h3>Payments</h3>
                <table width="100%" class="table table-striped table-hover tx-white">
                    <thead class="thead-colored thead-primary">
                    <tr>
                        <th>#</th>
                        <th>USER</th>
                        <th>VENDOR</th>
                        <th>AMOUNT</th>
                        <th>FEE/CUSTOMER ALLOCATIONS</th>
                        <th>RECEIVED ON</th>
                        <th><button class="btn btn-sm btn-success float-right" @click="openModal">New Payment</button></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-show="!loading" v-for="p in payments">
                        <td>{{p.id}}</td>
                        <td>{{p.user?.email}}</td>
                        <td>{{p.payment_platform}}</td>
                        <td>£{{parseFloat(p.amount).toFixed(2)}}</td>
                        <td>£{{parseFloat(p.fee_allocation).toFixed(2)}}/£{{parseFloat(p.customer_allocation).toFixed(2)}}</td>
                        <td>{{ p.received_on_formatted }}</td>
                        <td><button class="btn btn-sm btn-danger" @click="deletePayment(p)">DELETE</button></td>
                    </tr>
                    <tr v-if="payments.length < 1 && !loading">
                        <td colspan="7" class="text-center">Looks like there are no payments for this case...</td>
                    </tr>
                    <tr v-if="loading">
                        <td colspan="7" class="text-center">Loading data...</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <h3>Deductions</h3>
                <table width="100%" class="table table-striped table-hover tx-white">
                    <thead class="thead-colored thead-primary">
                    <tr>
                        <th>#</th>
                        <th>USER</th>
                        <th>AMOUNT</th>
                        <th>REASON</th>
                        <th>CREATED AT</th>
                        <th><button class="btn btn-sm btn-success float-right" @click="openDeductionModal">New Deduction</button></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-show="!loading" v-for="d in deductions">
                        <td>{{d.id}}</td>
                        <td>{{d.user?.email}}</td>
                        <td>£{{parseFloat(d.amount).toFixed(2)}}</td>
                        <td>{{d.reason}}</td>
                        <td>{{ d.created_at_formatted }}</td>
                        <td><button class="btn btn-sm btn-danger" @click="deleteDeduction(d)">DELETE</button></td>
                    </tr>
                    <tr v-if="deductions.length < 1 && !loading">
                        <td colspan="6" class="text-center">Looks like there are no deductions for this case...</td>
                    </tr>
                    <tr v-if="loading">
                        <td colspan="6" class="text-center">Loading data...</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div :id="'addPaymentModal' + claim.id" class="modal fade" style="display: none; padding-right: 17px;">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content bg-dark tx-white tx-size-sm">
                    <div class="modal-header bg-dark pd-x-20">
                        <h6 class="tx-14 mg-b-0 tx-uppercase tx-white tx-bold">Add Payment</h6>
                        <button type="button" class="close tx-white" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body bg-dark tx-white pd-20">

                        <div class="row no-gutters">
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="form-control-label">Payment Vendor: <span class="tx-danger">*</span></label>
                                    <input class="form-control form-control-dark" type="text" v-model="payment.vendor" placeholder="Cashflows / DCBL / ZZPS / Bank">
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="form-control-label">Payment Amount: <span class="tx-danger">*</span></label>
                                    <input class="form-control form-control-dark" type="number" step="0.01" min="0.01" v-model="payment.amount" placeholder="Payment amount">
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="form-control-label">Fee Allocation: <span class="tx-danger">*</span></label>
                                    <input class="form-control form-control-dark" :class="{'error': allocationError}" type="number" step="0.01" min="0.01" v-model="payment.fees" placeholder="Fee allocation">
                                    <p v-show="allocationError" class="text-danger">The sum of the fee allocation and customer allocation should equal the payment amount above!</p>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="form-control-label">Customer Allocation: <span class="tx-danger">*</span></label>
                                    <input class="form-control form-control-dark" :class="{'error': allocationError}" type="number" step="0.01" min="0.01" v-model="payment.customer" placeholder="Customer allocation">
                                    <p v-show="allocationError" class="text-danger">The sum of the fee allocation and customer allocation should equal the payment amount above!</p>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group mg-b-0">
                                    <label>Payment Received: <span class="tx-danger">*</span></label>
                                    <input class="form-control form-control-dark" type="date" v-model="payment.received" placeholder="DD/MM/YYYY">
                                </div><!-- form-group -->
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="form-control-label">Notes: <span class="tx-danger">*</span></label>
                                    <textarea class="form-control form-control-dark" v-model="payment.notes"></textarea>
                                </div>
                            </div>
                            <div class="col-12">
                                <p><span stlye="font-weight: bold;">Outstanding Balance:</span> £{{ this.currentBalance }}</p>
                            </div>
                            <div v-show="canMarkPaid" class="col-12">
                                <div class="form-group">
                                    <label class="form-control-label">Mark as Paid: <span class="tx-danger">*</span></label>
                                    <input class="form-control form-control-dark" type="checkbox" v-model="payment.paid">
                                </div>
                            </div>
                            <div v-show="canPostXero" class="col-12">
                                <div class="form-group">
                                    <label class="form-control-label">Post to Xero: <span class="tx-danger">*</span></label>
                                    <input class="form-control form-control-dark" type="checkbox" v-model="payment.postToXero">
                                </div>
                            </div>
                            <div class="col-12 mg-t-10">
                                <submit-button :background="'btn btn-info'" :text="'Create Payment'" :clicked="creating" @click="createPayment" />
                                <button class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                            </div><!-- form-group -->
                        </div><!-- row -->

                    </div><!-- modal-body -->
                    <div class="modal-footer bg-dark tx-white">
                        <button :id="'closeAddPaymentModal' + claim.id" type="button" class="btn btn-secondary tx-size-xs" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div><!-- modal-dialog -->
        </div>

        <div :id="'addDeductionModal' + claim.id" class="modal fade" style="display: none; padding-right: 17px;">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content bg-dark tx-white tx-size-sm">
                    <div class="modal-header bg-dark pd-x-20">
                        <h6 class="tx-14 mg-b-0 tx-uppercase tx-white tx-bold">Add Deduction</h6>
                        <button type="button" class="close tx-white" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body bg-dark tx-white pd-20">

                        <div class="row no-gutters">
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="form-control-label">Payment Amount: <span class="tx-danger">*</span></label>
                                    <input class="form-control form-control-dark" type="number" step="0.01" min="0.01" v-model="deduction.amount" placeholder="Deduction amount">
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="form-control-label">Reason: <span class="tx-danger">*</span></label>
                                    <textarea class="form-control form-control-dark" v-model="deduction.reason"></textarea>
                                </div>
                            </div>
                            <div v-if="totalPayments - totalDeductions - deduction.amount == 0" class="col-12 mg-t-10">
                                <div class="form-group">
                                    <label class="form-control-label">Cancel for Refund: <span class="tx-danger">*</span></label>
                                    <input class="form-control form-control-dark" type="checkbox" v-model="deduction.cancel">
                                </div>
                            </div>
                            <div class="col-12 mg-t-10">
                                <submit-button :background="'btn btn-info'" :text="'Create Deduction'" :clicked="creating" @click="createDeduction" />
                                <button class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                            </div><!-- form-group -->
                        </div><!-- row -->

                    </div><!-- modal-body -->
                    <div class="modal-footer bg-dark tx-white">
                        <button :id="'closeAddDeductionModal' + claim.id" type="button" class="btn btn-secondary tx-size-xs" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div><!-- modal-dialog -->
        </div>
    </div>
</template>

<script>
import {DateTime} from "luxon";
import SubmitButton from "@/components/SubmitButton";
import Swal from "sweetalert2";
import axios from "axios";
import authHeader from "@/services/auth-header";

export default {
    name: "ClaimPayments",
    components: {SubmitButton},
    props: {
        claim: {
            type: Object,
            required: true
        },
        client_id: {
            type: [Number, String],
            required: true
        }
    },
    data(){
        return {
            payments: [],
            deductions: [],
            loading: true,
            creating: false,
            deleting: false,
            payment: {
                vendor: "",
                amount: 0.00,
                received: "",
                paid: false,
                postToXero: false,
            },
            deduction: {
                amount: 0.00,
                reason: ""
            },
            allocationError: false
        }
    },
    mounted(){
        this.payment.received = DateTime.local().toFormat('yyyy-MM-dd');
        this.loadPayments();
        this.loadDeductions();
    },
    methods: {
        loadPayments(){
            axios.get(`https://api.varsanpr.com/api/claims/${this.claim.id}/payments?client_id=${this.client_id}`, {
                headers: authHeader()
            })
                .then(response => {
                    this.payments = response.data.payments;
                    this.payments.forEach((p) => {
                        p.received_on_formatted = DateTime.fromSeconds(p.received_on).toFormat('dd/MM/yyyy');
                    })
                    this.loading = false;
                    this.$emit('payments', this.payments.length);
                    this.claim.payments = this.payments;
                })
                .catch(error => {
                    this.$error("Failed loading payments.", error);
                    this.loading = false;
                });
        },
        loadDeductions(){
            axios.get(`https://api.varsanpr.com/api/claims/${this.claim.id}/deductions?client_id=${this.client_id}`, {
                headers: authHeader()
            })
            .then(response => {
                this.deductions = response.data.deductions;
                this.deductions.forEach((d) => {
                    d.created_at_formatted = DateTime.fromSeconds(d.created_at).toFormat('dd/MM/yyyy');
                })
                this.$emit('deductions', this.deductions.length);
                this.claim.deductions = this.deductions;
            })
            .catch(error => {
                this.$error("Failed loading deductions.", error);
            })
        },
        createPayment(){
            if(this.creating) return;
            this.creating = true;
            this.allocationError = false;

            console.log(this.payment.fees, this.payment.customer, this.payment.amount);

            if((parseFloat(this.payment.fees) + parseFloat(this.payment.customer)).toFixed(2) !== parseFloat(this.payment.amount).toFixed(2)){
                this.allocationError = true;
                this.creating = false;
                return;
            }

            axios.put(`https://api.varsanpr.com/api/claims/${this.claim.id}/payments`, {
                client_id: this.client_id,
                vendor: this.payment.vendor,
                value: parseFloat(this.payment.amount),
                received: this.payment.received,
                fees: parseFloat(this.payment.fees),
                customer: parseFloat(this.payment.customer),
                paid: this.payment.paid,
                postToXero: this.payment.postToXero,
            }, {
                headers: authHeader()
            })
                .then(response => {
                    this.$emit('changeMade');
                    this.$success("Created payment");
                    this.creating = false;
                    $('#closeAddPaymentModal' + this.claim.id).click();
                    this.loadPayments();
                })
                .catch(error => {
                    this.error("Failed to create payment", error);
                    this.creating = false;
                })
        },
        async deletePayment(payment){
            if(this.deleting) return;
            this.deleting = true;
            const {value: reason} = await Swal.fire({
                title: `Input a reason for deleting this payment:`,
                input: 'text',
                inputPlaceholder: 'Input a reason',
                showCancelButton: true
            });

            if(reason) {
                axios.delete(`https://api.varsanpr.com/api/claims/${this.claim.id}/payments/${payment.id}`, {
                    data: {
                        client_id: this.client_id,
                        reason: reason
                    },
                    headers: authHeader()
                })
                    .then(response => {
                        this.$emit('changeMade');
                        this.$success("Deleted payment");
                        this.payments = this.payments.filter((p) => {
                            return p.id !== payment.id
                        });
                    })
                    .catch(error => {
                        this.$error("Failed to delete payment", error);
                    })
                    .finally(() => {
                        this.deleting = false;
                    });
            }else{
                this.deleting = false;
            }
        },
        createDeduction(){
            if(this.creating) return;
            this.creating = true;

            axios.put(`https://api.varsanpr.com/api/claims/${this.claim.id}/deductions`, {
                client_id: this.client_id,
                amount: this.deduction.amount,
                reason: this.deduction.reason
            }, {
                headers: authHeader()
            })
                .then(response => {
                    this.$emit('changeMade');
                    this.$success("Created deduction");
                    if(this.deduction.cancel){

                        this.cancelForRefund();

                    }else{
                        this.creating = false;
                        $('#closeAddDeductionModal' + this.claim.id).click();
                        this.loadDeductions();
                    }
                })
                .catch(error => {
                    this.error("Failed to create deduction", error);
                    this.creating = false;
                })
        },
        cancelForRefund(){
            axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}/changestatus`, {
                reason: 'Refund',
                client_id: this.client_id,
                whitelist: false,
                paidonsite: false,
                status: 'Cancelled',
                cancellation_category: 'Other'
            }, {
                headers: authHeader()
            })
            .then(response => {
                this.creating = false;
                $('#closeAddDeductionModal' + this.claim.id).click();
                this.loadDeductions();
            })
            .catch(error => {
                this.error("Failed to cancel the claim", error);
                this.creating = false;
            });
        },
        async deleteDeduction(deduction){

                axios.delete(`https://api.varsanpr.com/api/claims/${this.claim.id}/deductions`, {
                    data: {
                        client_id: this.client_id,
                        deduction_id: deduction.id
                    },
                    headers: authHeader()
                })
                    .then(response => {
                        this.$emit('changeMade');
                        this.$success("Deleted deduction");
                        this.loadDeductions();
                    })
                    .catch(error => {
                        this.$error("Failed to delete deduction", error);
                    })
        },
        openModal(){
            $('#addPaymentModal' + this.claim.id).modal('toggle');
        },
        openDeductionModal(){
            $('#addDeductionModal' + this.claim.id).modal('toggle');
        },

    },
    computed: {
        totalPayments(){
            return this.payments.reduce((acc, p) => {
                return acc + parseFloat(p.amount);
            }, 0);
        },
        totalDeductions(){
            return this.deductions.reduce((acc, d) => {
                return acc + parseFloat(d.amount);
            }, 0);
        },
        totalFees(){
            return this.payments.reduce((acc, p) => {
                return acc + parseFloat(p.fee_allocation);
            }, 0);
        },
        totalCustomer(){
            return this.payments.reduce((acc, p) => {
                return acc + parseFloat(p.customer_allocation);
            }, 0);
        },
        currentBalance(){
            if(this.claim.amountToCollect) return this.claim.amountToCollect;

            if(this.claim.lock_fee){
                return this.claim.locked_fee - this.totalPayments;
            }

            if(this.claim.type === 'parking' || this.claim.type === 'evparking'){
                if(this.claim.dates?.date_final_keeper && this.claim.dates?.date_final_keeper !== null){
                    return 100 - this.totalPayments;
                }
                return 60 - this.totalPayments;
            }

            return 0;
        },
        canMarkPaid(){
            return parseFloat(this.currentBalance) - this.payment.amount <= 0;
        },
        canPostXero(){
            return this.$can('claims-xero');
        }
    }
}
</script>

<style scoped>
    .error {
        border: 1px solid red;
    }
</style>
